export interface VehiclePricingParams {
    msrp: number;
    isVehicleNew: boolean;
    pageType: 'SRP' | 'VDP';
    isMsrpRequired: boolean;
    exclusiveDiscount: number;
    markups?: InventoryVehiclePricingDetails['markups'];
    markupsTotal: number;
    rebatesApplied?: InventoryVehiclePricingDetails['rebates']['applied'];
    rebatesAppliedTotal: number;
    rebatesEveryone?: InventoryVehiclePricingDetails['rebates']['everyone'];
    rebatesEveryoneTotal: number;
    discounts?: InventoryVehiclePricingDetails['discounts'];
    discountsTotal: number;
}

export function useVehiclePricing({
    msrp = 0,
    isVehicleNew = false,
    pageType = 'SRP',
    isMsrpRequired = false,
    exclusiveDiscount = 0,
    markups,
    markupsTotal = 0,
    rebatesApplied,
    rebatesAppliedTotal = 0,
    rebatesEveryone,
    rebatesEveryoneTotal = 0,
    discounts,
    discountsTotal = 0,
}: Partial<VehiclePricingParams>) {
    const chapmanConnectStore = useChapmanConnectStore();
    const { arkonas } = useAppConfig();
    const isBmw =
        arkonas.site === 'caw' ||
        arkonas.site === 'cac' ||
        arkonas.site === 'hbmw';
    const isAudi = arkonas.site === 'ca1';
    const isAcura = arkonas.site === 'cao';

    // #region LOCAL REFS
    // This composable is initialized in some places without real values due to
    // other pieces of code requiring the composable to exist before a car
    // exists.
    // Therefore, these local refs allow the initial data to be updated after a
    // car exists.
    const _msrp = ref<VehiclePricingParams['msrp']>(msrp);
    const _isVehicleNew =
        ref<VehiclePricingParams['isVehicleNew']>(isVehicleNew);
    const _pageType = ref<VehiclePricingParams['pageType']>(pageType);
    const _isMsrpRequired =
        ref<VehiclePricingParams['isMsrpRequired']>(isMsrpRequired);
    const _exclusiveDiscount =
        ref<VehiclePricingParams['exclusiveDiscount']>(exclusiveDiscount);
    const _markups = ref<VehiclePricingParams['markups']>(markups);
    const _markupsTotal = ref<number>(markupsTotal);
    const _rebatesApplied =
        ref<VehiclePricingParams['rebatesApplied']>(rebatesApplied);
    const _rebatesAppliedTotal = ref<number>(rebatesAppliedTotal);
    const _rebatesEveryone =
        ref<VehiclePricingParams['rebatesEveryone']>(rebatesEveryone);
    const _rebatesEveryoneTotal = ref<number>(rebatesEveryoneTotal);
    const _discounts = ref<VehiclePricingParams['discounts']>(discounts);
    const _discountsTotal = ref<number>(discountsTotal);

    /** Update all local car value refs. */
    function updateValues(data: VehiclePricingParams) {
        _msrp.value = data.msrp;
        _isVehicleNew.value = data.isVehicleNew;
        _pageType.value = data.pageType;
        _isMsrpRequired.value = data.isMsrpRequired;
        _exclusiveDiscount.value = data.exclusiveDiscount;
        _markups.value = data.markups;
        _markupsTotal.value = data.markupsTotal;
        _rebatesApplied.value = data.rebatesApplied;
        _rebatesAppliedTotal.value = data.rebatesAppliedTotal;
        _rebatesEveryone.value = data.rebatesEveryone;
        _rebatesEveryoneTotal.value = data.rebatesEveryoneTotal;
        _discounts.value = data.discounts;
        _discountsTotal.value = data.discountsTotal;
    }
    // #endregion LOCAL REFS

    // #region COMPUTED LOCAL REFS
    const validDiscounts = computed(() => {
        if (!_discounts.value) return {};

        const entries = Object.entries(_discounts.value);

        const filtered = entries.filter(([_, value]) => {
            // Do not show tiny tiny savings
            if (value > 300) return true;
        });

        return Object.fromEntries(filtered);
    });

    const markupsTotalValue = computed(() => {
        if (_markups.value) {
            return Object.values(_markups.value).reduce(
                (acc, markup) => acc + Math.abs(markup.value),
                0
            );
        }

        return Math.abs(_markupsTotal.value);
    });

    const rebatesAppliedTotalValue = computed(() => {
        if (_rebatesApplied.value) {
            return Object.values(_rebatesApplied.value).reduce(
                (acc, rebateApplied) => acc + Math.abs(rebateApplied.value),
                0
            );
        }

        return Math.abs(_rebatesAppliedTotal.value);
    });

    const rebatesEveryoneTotalValue = computed(() => {
        if (_rebatesEveryone.value) {
            return Object.values(_rebatesEveryone.value).reduce(
                (acc, rebateEveryone) => acc + Math.abs(rebateEveryone.value),
                0
            );
        }

        return Math.abs(_rebatesEveryoneTotal.value);
    });

    const discountsTotalValue = computed(() => {
        if (_discounts.value) {
            return Object.values(_discounts.value).reduce(
                (acc, discount) => acc + Math.abs(discount),
                0
            );
        }

        return Math.abs(_discountsTotal.value);
    });
    // #endregion

    /**
     * The price before discounts are applied.
     */
    const normalizedMsrpValue = computed(() => {
        if (!_msrp.value || _msrp.value <= 0) return 0;

        return _msrp.value;
    });

    /**
     * The initial price formatted like $234,000.
     */
    const normalizedMsrpFormatted = computed(() => {
        return formatPrice(normalizedMsrpValue.value);
    });

    /**
     * The label to the left of the initial price.
     */
    const normalizedMsrpLabel = computed(() => {
        // For all pages with new vehicles.
        if (_isVehicleNew.value) {
            return 'MSRP';
        }

        // If used, and page is SRP
        if (_pageType.value === 'SRP' && !isBmw) {
            return 'Was';
        }

        // If used, and page is VDP
        return 'Retail';
    });

    const normalizedExclusiveDiscount = computed(() => {
        if (!_exclusiveDiscount.value || _exclusiveDiscount.value <= 0) {
            return 0;
        }

        return _exclusiveDiscount.value;
    });

    /**
     * This computed determines whether or not we are allowed to show a final
     * price that is NOT the msrp. Some sites require that we only show the
     * MSRP if we do not have any associated customer information.
     */
    const isFinalPriceRestricted = computed(() => {
        return _isMsrpRequired.value && !chapmanConnectStore.hasCustomerData;
    });

    /**
     * This is the price of the vehicle when final price is not restricted.
     */
    const notLoggedInValue = computed(() => {
        /**
         * Acura Compliance
         * We can show a final price that is higher than msrp if it includes
         * markups.
         */
        if (isAcura && isFinalPriceRestricted.value) {
            return normalizedMsrpValue.value + markupsTotalValue.value;
        }

        /**
         * Show initial price if savings are restricted and customer info does
         * not exist.
         */
        if (isFinalPriceRestricted.value) {
            return normalizedMsrpValue.value;
        }

        let currentValue = normalizedMsrpValue.value;

        currentValue += markupsTotalValue.value;
        currentValue -= rebatesAppliedTotalValue.value;
        currentValue -= rebatesEveryoneTotalValue.value;
        currentValue -= discountsTotalValue.value;

        return currentValue;
    });

    /**
     * This is the price of the vehicle when final price is restricted.
     */
    const loggedInValue = computed(() => {
        let currentValue = normalizedMsrpValue.value;

        currentValue += markupsTotalValue.value;
        currentValue -= rebatesAppliedTotalValue.value;
        currentValue -= rebatesEveryoneTotalValue.value;
        currentValue -= discountsTotalValue.value;
        currentValue -= normalizedExclusiveDiscount.value;

        return currentValue;
    });

    /**
     * The actual final price after all discounts.
     */
    const finalPriceValue = computed(() => {
        if (!chapmanConnectStore.hasCustomerData) return notLoggedInValue.value;

        return loggedInValue.value;
    });

    /**
     * The discounted price formatted like $234,000.
     */
    const finalPriceFormatted = computed(() => {
        if (finalPriceValue.value <= 0) return 'Call';

        return formatPrice(finalPriceValue.value);
    });

    const _normalizedMsrpLabel = computed(() => {
        /**
         * For used cars, show `Retail`
         */
        if (!_isVehicleNew.value) {
            return 'Retail';
        }

        /**
         * Audi Compliance
         * `MSRP` must be shown as `Total MSRP`
         */
        if (isAudi) {
            return 'Total MSRP';
        }

        /**
         * Default is `MSRP`
         */
        return 'MSRP';
    });

    const finalPriceLabel = computed(() => {
        /**
         * Since `isFinalPriceRestricted` is already used inside
         * `finalPriceValue`, we don't need to check it in this computed and can
         * just check the actual value of `finalPriceValue`.
         */

        if (finalPriceValue.value === normalizedMsrpValue.value) {
            return _normalizedMsrpLabel.value;
        }

        /**
         * On SRP, show nothing when savings exists.
         */
        if (
            _pageType.value === 'SRP' &&
            finalPriceValue.value < normalizedMsrpValue.value
        )
            return '';

        /**
         * Show `MyChapman` on the VDP if exclusive discount exists and customer
         * info exists.
         */
        if (
            normalizedExclusiveDiscount.value &&
            chapmanConnectStore.hasCustomerData
        )
            return 'MyChapman Price';

        /**
         * All vehicles will show `Chapman Price` if no other restrictions are
         * in place and savings exist, only on the VDP.
         */
        return 'Chapman Price';
    });

    const savingsValue = computed(() => {
        // Do not show savings if vehicle does not have a price.
        if (
            finalPriceValue.value <= 0 ||
            finalPriceValue.value > normalizedMsrpValue.value
        ) {
            return 0;
        }

        const savings = normalizedMsrpValue.value - finalPriceValue.value;

        // Do not show tiny tiny savings
        if (savings > 0 && savings < 300) return 0;

        return savings;
    });

    /**
     * The savings formatted like $234,000.
     */
    const savingsFormatted = computed(() => {
        return formatPrice(savingsValue.value);
    });

    /**
     * Determines whether or not any savings exist
     */
    const hasSavings = computed(() => savingsValue.value > 0);

    return {
        updateValues,
        validDiscounts,
        markupsTotalValue,
        rebatesAppliedTotalValue,
        rebatesEveryoneTotalValue,
        discountsTotalValue,

        notLoggedInValue,
        loggedInValue,

        normalizedMsrpValue,
        normalizedMsrpFormatted,
        normalizedMsrpLabel,
        normalizedExclusiveDiscount,

        finalPriceValue,
        finalPriceFormatted,
        finalPriceLabel,

        savingsValue,
        savingsFormatted,
        hasSavings,
    };
}
